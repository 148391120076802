<template>
  <div>
    <eden-page-header :title="'Services'" :subtitle="subtitle" />
    <div class="loader" v-loading="fetching" v-if="fetching"></div>
    <div v-if="!fetching">
      <component
        :is="`${service}-form`"
        :action="'edit'"
        :menu-id="menuId"
        :data="data"
      />
    </div>
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
import onetime from "@/requests/services/meal/onetime/index";

export default {
  name: "OneTimeEdit",
  data() {
    return {
      fetching: true,
      data: {
        menu_items: [],
        image: null,
        combo_image: "",
        service_type_name: "",
        meal_type: "",
        class_category: null,
        side_category: "",
        main_with_sides: false,
        side_types: [],
        food_tags: [],
        chef_note: "",
        preset_combos: [],
      },
    };
  },
  components: {
    OneTimeOrderMenuForm: () =>
      import(
        "@/components/Services/Meal/MealOneTimeOrder/NewMenuOneTime/MenuOneTimeForm"
      ),
  },
  computed: {
    service() {
      return this.$route.params.service;
    },
    subtitle() {
      return "One Time Menu Edit";
    },
    menuId() {
      return this.$route.params.id;
    },
    location() {
      return this.$store.getters.location;
    },
    mealItems() {
      return this.$store.getters.meal_item_list;
    },
  },
  async created() {
    this.$route.params.service = "one-time-order-menu";
    const itemsFetched = this.mealItems.length > 0;
    if (!itemsFetched) {
      await this.$store.dispatch(actions.GET_MEAL_ITEM_LIST).then(async () => {
        //   console.log(res);
        await this.getMenu();
      });
    } else {
      await this.getMenu();
    }
    this.fetching = false;
  },
  methods: {
    async getMenu() {
      await onetime
        .get(this.menuId)
        .then((response) => {
          if (response.data.status) {
            let mealItems = this.$store.getters.meal_item_list;
            const { data } = response.data;

            this.data.is_draft = data?.is_draft === 1;
            this.data.menu_items = data?.item_groups.map((item) => {
              let extraMealItem = mealItems.find(
                (generalItem) => generalItem.id === item.main_item_id,
              );
              return {
                id: item.main_item_id,
                name: item.main_meal_name,
                image_url: item.main_meal_img,
                side_types: item.side_types,
                meal_type: item.meal_type,
                service_type_category: extraMealItem?.type,
                service: extraMealItem?.service_name,
                preset_combos_full: extraMealItem?.preset_combos_full,
                preset_combos: extraMealItem?.preset_combos,
                extra: item,
                combos: item.menu_combo_items.map((comb) => {
                  const image = comb.image_url;
                  delete comb.image_url;
                  return {
                    ...comb,
                    image,
                    combo_id:  comb.id,
                    combo: comb.side_items
                      .map((item) => item.meal_name)
                      .join(", "),
                    food_tags: this.validateFoodTags(comb.tags).split(","),
                    comboId: comb.side_items.map(
                      (item) => item.service_type_id,
                    ),
                  };
                }),
              };
            });

            this.fetching = false;
          }
        })
        .catch((error) => {
          this.fetching = false;
          console.log(error);
          const errorMessage = error.response?.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    validateFoodTags(tags) {
      try {
        if (!tags || !tags.length) {
          return "";
        }
        let tagList;
        if (Array.isArray(tags)) {
          tagList = tags;
        }
        if (typeof tags === "string") {
          tagList = JSON.parse(tags);
        }
        return tagList.flat().join(", ");
      } catch (error) {
        return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  height: 80vh;
  width: 100%;
}
</style>
